<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          {{ PdmConfigValuesEntity.CONFIG_VALUES_NAME }}({{
            PdmConfigValuesEntity.HospName
          }})
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <select_hospInfo
          :dense="true"
          outlined
          class="col-7"
          label="选择机构"
          v-model="PdmConfigValuesEntity.HOSPID"
          v-model:valueCode="PdmConfigValuesEntity.HOSPID"
        />
        <ConfigKey_select
          :dense="true"
          outlined
          class="col-4"
          label="选择主模块"
          v-model="PdmConfigValuesEntity.CONFIG_KEY_NO"
          v-model:valueCode="PdmConfigValuesEntity.CONFIG_KEY_NO"
        />
        <q-input
          label="布局模块名称"
          dense
          outlined
          class="col-4"
          v-model="PdmConfigValuesEntity.CONFIG_VALUES_NAME"
        />
        <q-input
         @click="onImgClick"
          label="布局模块图片"
          dense
          outlined
          readonly 
          class="col-5"
          v-model="PdmConfigValuesEntity.CONFIG_VALUES_TU"
        />
        <q-input
          label="排列序号"
          dense
          outlined
          class="col-2"
          v-model="PdmConfigValuesEntity.CONFIG_VALUES_NO"
        />
        <q-input
          label="小程序跳转路径"
          dense
          outlined
          class="col-11"
          v-model="PdmConfigValuesEntity.CONFIG_VALUES_PATH"
        />
        <q-btn-toggle
          v-model="PdmConfigValuesEntity.CONFIG_VALUES_IS_SWITCH"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '不启用', value: 0 },
            { label: '启用', value: 1 },
          ]"
        />
        <q-btn-toggle
          v-model="PdmConfigValuesEntity.IS_SHOW"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '不展示', value: 0 },
            { label: '展示', value: 1 },
          ]"
        />
        <q-btn-toggle
          v-model="PdmConfigValuesEntity.CONFIG_VALUES_TYPE"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '门诊', value: 1 },
            { label: '住院', value: 2},
            { label: '体检', value: 3},
            { label: '其他', value: 4},
            { label: '我的', value: 99},
          ]"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>

  <q-dialog v-model="imgShowDlg" persistent>
    <imageInfo />
  </q-dialog>

</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfigKey_select from "@/views/pdm/comm/configKey_select.vue";
import imageInfo from "./imageInfo.vue";
import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
export default {
  components: {
    ConfigKey_select,
    select_hospInfo,
    imageInfo,
  },
  props: {},
  computed: {
    ...mapState("PdmConfigValues", [
      "PdmConfigValuesShowDlg",
      "PdmConfigValuesEntity",
      "PdmConfigValuesList",
      "ImgShowDlg"
    ]),
    imgShowDlg: {
      get() {
        return this.ImgShowDlg;
      },
      set(val) {
        this.$store.commit("PdmConfigValues/setImgShowDlg", val);
      },
    },
  },
  emits: ['onLoadData'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("PdmConfigValues", [
      "actUpdatePdmConfigValues",
      "actGetPdmConfigValuesList",
    ]),
    onOK() {
      this.actUpdatePdmConfigValues({
        // HospID: this.$store.getters["appUser/hospID"],
        // CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmConfigValuesEntity,
      }).then((res) => {
        if (res.Code == 0) {
          this.$emit("onLoadData");
          // setTimeout(()=> {
          //   this.$emit("onLoadData");
          // }, 200);
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onImgClick(){
      this.$store.commit("PdmConfigValues/setImgShowDlg", true);
    }
  },
};
</script>
