/*------------------------------------------------
// File Name:PDM_CONFIG_VALUES.js
// File Description:PDM_CONFIG_VALUES API方法js接口
// Author:zxl
// Create Time:2022/08/22 12:20:52
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _configValues: "/PDM_CONFIG_VALUES"
}

export default api


//-------------------创建对象-------------------
export function CreatePDM_CONFIG_VALUESEntity(parameter) {
    return axios({
        url: api._configValues + "/CreatePDM_CONFIG_VALUESEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_CONFIG_VALUESList(parameter) {
    return axios({
        url: api._configValues + "/GetPDM_CONFIG_VALUESList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_CONFIG_VALUESListByPage(parameter) {
    return axios({
        url: api._configValues + "/GetPDM_CONFIG_VALUESListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_CONFIG_VALUESEntity(parameter) {
    return axios({
        url: api._configValues + "/GetPDM_CONFIG_VALUESEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_CONFIG_VALUESEntity(parameter) {
    return axios({
        url: api._configValues+ "/UpdatePDM_CONFIG_VALUESEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------保存对象集合---------------
export function UpdatePDM_CONFIG_VALUESlist(parameter) {
    return axios({
        url: api._configValues+ "/UpdatePDM_CONFIG_VALUESlist",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_CONFIG_VALUESEntity(parameter) {
    return axios({
        url: api._configValues + "/DeletePDM_CONFIG_VALUESEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取图片列表---------------
export function GetImageList(parameter) {
    return axios({
        url: api._configValues + "/GetImageList",
        method: 'post',
        data: parameter
    })
}

