<template>
  <q-card style="width: 1000px; max-width: 70vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          小程序图标列表
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <div v-for="(item, index) in data" :key="index" class="q-ma-sm">
          <q-img @click="onImgClick(item)" :src="item.Url" style="width: 100px;height: 100px;" fit="scale-down" />
        </div>
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="关闭" v-close-popup />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dlg from "@/config/dialog";
import * as ConfigValues from "@/api/pdm/configValues";

export default {
  components: {},
  props: {},
  computed: {
    ...mapState("PdmConfigValues", [
      "PdmConfigValuesShowDlg",
      "PdmConfigValuesEntity",
      "PdmConfigValuesList",
    ]),
  },
  data() {
    return {
      HospID: null,
      data: [],
    };
  },
  mounted() {
    this.onLoadDatas();
  },
  methods: {
    ...mapActions("PdmConfigValues", [
      "actUpdatePdmConfigValues",
      "actGetPdmConfigValuesList",
    ]),
    onLoadDatas() {
      ConfigValues.GetImageList({}).then((res) => {
        if (res.Code == 0) {
          this.data = res.Data;
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onImgClick(item){
        this.PdmConfigValuesEntity.CONFIG_VALUES_TU=item.Name;
        this.$store.commit("PdmConfigValues/setImgShowDlg", false);
    },
    
  },
};
</script>
